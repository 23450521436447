import React from "react";
import PropTypes from "prop-types";
import { constructAttributeClasses, getTextWithLinks } from "helpers/cms-attributes-helper";
import { CMSHedTypes } from "rtr-constants";
import { atomHedAttributesPropType, atomTextLinksPropType } from "../../propTypes";
import classnames from "classnames";

class AtomHed extends React.Component {
  static propTypes = {
    customClass: PropTypes.string,
    hedAttributes: atomHedAttributesPropType,
    mobileHedAttributes: atomHedAttributesPropType,
    mobileText: PropTypes.string,
    mobileTextLinks: atomTextLinksPropType,
    text: PropTypes.string,
    textLinks: atomTextLinksPropType,
    type: PropTypes.oneOf(Object.values(CMSHedTypes)),
  };

  static className = "customizable-atom";

  renderHeaderTag = (text, links) => {
    const type = Object.values(CMSHedTypes).includes(this.props.type) ? this.props.type : CMSHedTypes.H2;
    //If links are present from the CMS entry, this parses them into a Fragment that we can render
    //Otherwise, it just returns the text and we treat it as raw HTML
    const parsedText = getTextWithLinks(text, links);

    if (typeof parsedText === "string") {
      return React.createElement(type, { dangerouslySetInnerHTML: { __html: parsedText } });
    } else {
      return React.createElement(type, {}, parsedText);
    }
  };

  renderSeparator = () => {
    // The separator is a short underline for the hed
    if (!this.props.hedAttributes?.separator) {
      return;
    }

    return <div className="separator" data-test-id="separator"></div>;
  };

  renderHed = (text, links, attributes, mediaSuffix) => {
    const { hedAttributes, mobileText } = this.props;
    if (!text) return;

    // If there's no mobile attributes, default to the regular ones
    const relevantAttributes = !attributes || !Object.keys(attributes).length ? hedAttributes : attributes;

    // If we have a mobile hed, we do not want to display the desktop one on mobile
    // and vice-versa; this adds the media class to the div
    const classNames = classnames(constructAttributeClasses(this.constructor.className, relevantAttributes), {
      [`${this.constructor.className}--${mediaSuffix}`]: mobileText,
    });

    return (
      <div className={classNames}>
        {this.renderHeaderTag(text, links)}
        {this.renderSeparator()}
      </div>
    );
  };

  render() {
    const {
      customClass,
      hedAttributes,
      mobileHedAttributes,
      mobileText,
      mobileTextLinks,
      text,
      textLinks,
    } = this.props;
    const classNames = classnames("atom-hed", { [customClass]: customClass });
    return (
      <div className={classNames} data-test-id="atom-hed">
        {this.renderHed(text, textLinks, hedAttributes, "tablet-and-desktop")}
        {this.renderHed(mobileText, mobileTextLinks, mobileHedAttributes, "mobile")}
      </div>
    );
  }
}

export default AtomHed;
