import React from "react";
import PropTypes from "prop-types";
import ProductAvailability from "helpers/product-availability";

class AvailableSizes extends React.Component {
  static propTypes = {
    filteredSizes: PropTypes.arrayOf(PropTypes.string),
    skus: PropTypes.arrayOf(PropTypes.object).isRequired,
    isClearanceGrid: PropTypes.bool,
    isUnlimitedGrid: PropTypes.bool,
  };

  static defaultProps = {
    filteredSizes: [],
    isClearanceGrid: false,
    isUnlimitedGrid: false,
    skus: [],
  };

  static AvailabilityType = {
    Unlimited: "unlimitedAvailabilities",
    Classic: "rentalAvailabilities",
    Clearance: "clearanceAvailabilities",
  };

  getAvailabilityType = () => {
    const { isClearanceGrid, isUnlimitedGrid } = this.props;
    const { Classic, Clearance, Unlimited } = AvailableSizes.AvailabilityType;

    if (isClearanceGrid) {
      return Clearance;
    } else if (isUnlimitedGrid) {
      return Unlimited;
    } else {
      return Classic;
    }
  };

  getFilteredAvailableSizes = () => {
    const { skus, filteredSizes } = this.props;
    const availabilityType = this.getAvailabilityType();

    return ProductAvailability.filteredSkus(skus, filteredSizes, availabilityType).map(sku => sku.size);
  };

  getAllAvailableSizes = () => {
    const { skus, filteredSizes } = this.props;
    const availabilityType = this.getAvailabilityType();

    return ProductAvailability.availableSkus(skus, filteredSizes, availabilityType).map(sku => sku.size);
  };

  getAvailableSizes = () => {
    const { filteredSizes } = this.props;

    return filteredSizes.length ? this.getFilteredAvailableSizes() : this.getAllAvailableSizes();
  };

  availableSizeDescription = sizes => {
    const displaySizes = sizes.slice(0, 4).join(", ") + (sizes.length > 5 ? "..." : "");

    return <p className="universal-xsmall">{(sizes.length > 1 ? "Sizes " : "Size ") + displaySizes}</p>;
  };

  render() {
    const sizes = this.getAvailableSizes();

    if (!sizes.length) {
      return null;
    }

    return <div className="grid-product-card-available-sizes mq__desktop">{this.availableSizeDescription(sizes)}</div>;
  }
}

export default AvailableSizes;
