import React from "react";
import PropTypes from "prop-types";

class ProductAdminLinks extends React.Component {
  static propTypes = {
    productId: PropTypes.string,
  };

  render() {
    return (
      <div className="grid-product-card-admin__actions small-copy">
        <a href={"/pdp/admin_redirect/" + this.props.productId} target="_blank" rel="noreferrer">
          Edit Item
        </a>
        <span> | </span>
        <a href={"/similar/" + this.props.productId + "/products"} target="_blank" rel="noreferrer">
          Similar
        </a>
      </div>
    );
  }
}

export default ProductAdminLinks;
