// cSpell:ignore xsmall

import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import { compose } from "redux";
import MembershipHelpers from "helpers/membership-helpers";
import MembershipUpgradesHelpers from "helpers/membership-upgrades-helpers";
import { membershipStatePropType, userDataPropType } from "components/propTypes";
import { normalizePrice, retailPriceFormatted, usdPriceIntoFloat } from "helpers/PricingHelper";
import { isBulk, isClearance, isRental } from "helpers/price-helper";
import PrivateLabelDesignersList from "components/../private-label-designers-list";
import AvailabilityFilterHelpers from "helpers/availability-filter-helpers";
import { Flags } from "components/source/hoc/with-feature-flags";
import { selectFeatureFlagEnabled } from "selectors/featureFlagSelectors";
import { priceStringIntoFloat } from "helpers/invoice-helper";

export class AtomProductCardPriceComponent extends Component {
  static propTypes = {
    additionalClass: PropTypes.string,
    ceBagCount: PropTypes.object, // provided by connect below
    designerId: PropTypes.string,
    filters: PropTypes.shape({
      date: PropTypes.string,
      unlimitedMinAvailability: PropTypes.number,
    }),
    isBuyNow: PropTypes.bool,
    membershipState: membershipStatePropType, // provided by connect below
    price: PropTypes.shape({
      adjusted: PropTypes.number,
      base: PropTypes.number,
      discounts: PropTypes.array,
      maximum: PropTypes.number,
      minimum: PropTypes.number,
      isBulk: PropTypes.bool,
      isClearance: PropTypes.bool,
      isRental: PropTypes.bool,
    }),
    priceDisplayOverrides: PropTypes.shape({
      showClassicPricing: PropTypes.bool,
      showMembershipPricing: PropTypes.bool,
      showOnlyRetailPrice: PropTypes.bool,
    }),
    purchasePrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // but I think it is supposed to be a string
    retailPrice: PropTypes.string,
    userData: userDataPropType, // provided by connect below
    unlimitedAlwaysOnAddOnsFF: PropTypes.bool,
  };

  static defaultProps = {
    additionalClass: "",
    priceDisplayOverrides: {},
  };

  hasDateFilter() {
    return Boolean(this.props.filters?.date);
  }

  /**
   * If specific rules for displaying any prices exist, we should always use only the defined prices.
   * Otherwise, defer to business rules around intent and filtering.
   */
  hasPriceDisplayOverrides() {
    const { showClassicPricing, showMembershipPricing, showOnlyRetailPrice } = this.props.priceDisplayOverrides;

    return showClassicPricing || showMembershipPricing || showOnlyRetailPrice;
  }

  renderRentalPrices() {
    const { isBuyNow, price } = this.props;

    if (isBuyNow || !isRental(price)) {
      return null;
    }

    return (
      <>
        {this.renderClassicRentalPrice()}
        {this.renderMembershipRentalPrice()}
      </>
    );
  }

  renderMembershipRentalPrice() {
    if (!this.shouldRenderMembershipRentalPrice()) {
      return null;
    }

    return (
      <div className={"product-card-price__line-item primary-price"} data-test-id="membership-rental-price">
        <span>{this.getMembershipPriceAnnotationString()}</span>
        <span>{this.getMembershipRentalPriceString()}</span>
      </div>
    );
  }

  /**
   * This method primarily handles instances where the BE doesn't properly return $0 pricing
   */
  getMembershipRentalPriceString() {
    const { ceBagCount, membershipState, price } = this.props;

    // RF [EXPLANATION] 12/16/20: Override price as $0 for a subscriber with
    // remaining shipments as long as they haven't marked anything as return promise
    // (even if number of at-home + newly-added is over the spot count)
    const userHasNotMarkedItemsReturnPromise =
      MembershipHelpers.hasShipmentsCount(membershipState) &&
      MembershipHelpers.hasAllItemsAtHome(membershipState) &&
      !MembershipHelpers.hasAtLeastOneReturnPromise(membershipState);
    //Only show adjusted price (i.e. upgrade price) if the user's bag is full
    const userHasRemainingOpenSlots =
      (ceBagCount?.members ?? 0) < MembershipHelpers.fetchNumOpenSlots(this.props.membershipState);

    //Non-subscribers may be viewing products from a Classic grid request, which return classic prices
    //Regardless, we always want to show them $0 pricing
    const isNonSubscriber = !MembershipHelpers.isActiveMembership(membershipState);
    let priceString;

    if (userHasNotMarkedItemsReturnPromise || userHasRemainingOpenSlots || isNonSubscriber) {
      priceString = "$0";
    } else {
      const { adjusted, discounts, minimum } = price;

      // use membership state upgrade price so we don't have to refresh carousels when users add to bag
      if (this.shouldSeeExtraSpotAnnotation()) {
        priceString = "$" + priceStringIntoFloat(membershipState?.baseSlotUpgradePrice);
      }
      //This is the logic difference from Classic pricing
      //Membership can have an adjusted value of $0, Classic should not
      else if (discounts?.length) {
        priceString = normalizePrice(Math.ceil(adjusted));
      } else {
        priceString = normalizePrice(minimum);
      }
    }

    return priceString;
  }

  getMembershipPriceAnnotationString() {
    let annotation;

    if (this.shouldSeeExtraSpotAnnotation()) {
      annotation = "Extra spot";
    } else if (this.isPreviewingMembership()) {
      annotation = "With membership";
    } else {
      annotation = "Rent from";
    }

    return annotation;
  }

  isViewingUpgradePricing() {
    const { ceBagCount, membershipState, unlimitedAlwaysOnAddOnsFF, userData } = this.props;
    return MembershipUpgradesHelpers.isViewingUpgradePricing(
      membershipState,
      userData,
      ceBagCount,
      unlimitedAlwaysOnAddOnsFF
    );
  }

  shouldSeeExtraSpotAnnotation() {
    const { membershipState } = this.props;
    return this.isViewingUpgradePricing() && !MembershipHelpers.canInitiateSwapFlow(membershipState);
  }

  isPreviewingMembership() {
    const { userData } = this.props;
    return !MembershipHelpers.isSubscriptionMember(userData);
  }

  renderClassicRentalPrice() {
    if (!this.shouldRenderClassicRentalPrice()) {
      return null;
    }

    return (
      <div className={"product-card-price__line-item primary-price"} data-test-id="classic-rental-price">
        {this.getClassicRentalPriceDisplay()}
      </div>
    );
  }

  shouldRenderMembershipRentalPrice() {
    const { priceDisplayOverrides, userData } = this.props;

    if (this.hasPriceDisplayOverrides()) {
      return priceDisplayOverrides.showMembershipPricing;
    }

    //Any user with a classic lens should not see membership pricing
    if (MembershipHelpers.isClassicLens(userData)) {
      return false;
    }

    //This is a new requirement to ensure we don't render a zero dollar "for rent". These zero dollar annotation scenarios are ok:
    return this.shouldSeeExtraSpotAnnotation() || this.isPreviewingMembership();
  }

  shouldRenderClassicRentalPrice() {
    const { filters, membershipState, priceDisplayOverrides, userData } = this.props;

    if (this.hasPriceDisplayOverrides()) {
      return priceDisplayOverrides.showClassicPricing;
    }

    //Subscribers should not see Classic pricing, unless they have a date filter set or somehow get an explicit classic lens set
    const activeSubscriberWithoutDates =
      MembershipHelpers.isActiveMembership(membershipState) &&
      !this.hasDateFilter() &&
      !MembershipHelpers.isClassicLens(userData);
    //Non subscribers explicitly viewing available Unlimited inventory shouldn't see Classic pricing
    const isNonSubViewingAvailableMembership =
      !MembershipHelpers.isActiveMembership(membershipState) &&
      AvailabilityFilterHelpers.membershipMinAvailability(userData, filters) === 1;

    if (activeSubscriberWithoutDates || isNonSubViewingAvailableMembership) {
      return false;
    }

    //Otherwise, we always want to show Classic pricing
    return true;
  }

  getClassicRentalPriceDisplay() {
    const { adjusted, discounts, minimum } = this.props.price;

    let priceString;

    //Some grid views (ie View All) are membership availability requests with prices that have an adjusted value of 0
    //This happens when no specific dates are entered by the user
    //If this happens, we don't want to show adjusted price, since there are no free Classic rentals
    if (discounts?.length && adjusted !== 0) {
      priceString = normalizePrice(Math.ceil(adjusted));
    } else {
      priceString = normalizePrice(minimum);
    }

    return (
      <>
        <span>Rent from </span>
        <span>{priceString}</span>
      </>
    );
  }

  /**
   * For Membership and No Intent states, we show only a retail price annotation and nothing else.
   */
  renderOnlyRetailPrice() {
    const { retailPrice } = this.props;

    return (
      <div className="product-card-price__line-item universal-small--secondary">
        <span>{this.getRetailCopy()} </span>
        <span>{retailPriceFormatted(retailPrice)}</span>
      </div>
    );
  }

  renderRetailPrice() {
    const { membershipState, price, retailPrice } = this.props;

    if (isBulk(price) || !retailPrice) return;

    const floatPurchasePrice = usdPriceIntoFloat(this.getPurchasePrice());
    const isActiveMembership = MembershipHelpers.isActiveMembership(membershipState);
    // If the purchase price is the same as the retail price, we don't
    // show the original retail price

    if (isActiveMembership && floatPurchasePrice === usdPriceIntoFloat(retailPrice)) {
      return null;
    }

    return (
      <div className={classNames("product-card-price__line-item", "universal-small--secondary")}>
        <span>{this.getRetailCopy()} </span>
        <span>{retailPriceFormatted(retailPrice)}</span>
      </div>
    );
  }

  getRetailCopy() {
    const { designerId } = this.props;
    const privateLabelCopy = "Comparable Value";
    const externalLabelCopy = "Retail Value";

    return PrivateLabelDesignersList.some(designer => designer.id === designerId)
      ? privateLabelCopy
      : externalLabelCopy;
  }

  getPurchasePrice = () => {
    const { price, purchasePrice } = this.props;

    if (isBulk(price) || isClearance(price)) {
      return price?.minimum;
    }

    if (purchasePrice) {
      return purchasePrice;
    }
  };

  renderPurchasePrice() {
    const purchasePrice = this.getPurchasePrice();

    if (!this.props.isBuyNow && (!this.shouldRenderPurchasePrice() || !purchasePrice)) {
      return null;
    }

    return (
      <div className="product-card-price__line-item purchase-price">
        <span>To Buy </span>
        <span>{normalizePrice(usdPriceIntoFloat(purchasePrice))}</span>
      </div>
    );
  }

  shouldRenderPurchasePrice() {
    const { isBuyNow, membershipState, price } = this.props;
    //These are purchase-only items, so always show the price
    if (isBuyNow || isClearance(price) || isBulk(price)) {
      return true;
    }

    //Otherwise, only show purchase price to subscribers
    return MembershipHelpers.isActiveMembership(membershipState);
  }

  render() {
    if (!this.props.isBuyNow && this.props.priceDisplayOverrides.showOnlyRetailPrice) {
      return this.renderOnlyRetailPrice();
    }

    const { additionalClass } = this.props;

    return (
      <div className={classNames("product-card-price", "universal-small", additionalClass)}>
        <span className="block">
          {this.renderPurchasePrice()}
          {this.renderRentalPrices()}
          {this.renderRetailPrice()}
        </span>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ceBagCount: state.ceBagCount,
  filters: state.workingFilters,
  membershipState: state.membershipState,
  unlimitedAlwaysOnAddOnsFF: selectFeatureFlagEnabled(Flags.UNLIMITED_ALWAYS_ON_ADD_ONS)(state),
  userData: state.userData,
});

export default compose(connect(mapStateToProps))(AtomProductCardPriceComponent);
