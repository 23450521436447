import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const AtomUrgencyMessage = ({ additionalClassname, message }) => {
  const className = "urgency-message";
  const classNames = classnames(className, {
    [additionalClassname]: additionalClassname,
  });

  if (!message) {
    return null;
  }

  return (
    <div className={classNames} data-test-id={"product-card-urgency-message"}>
      {message}
    </div>
  );
};

AtomUrgencyMessage.propTypes = {
  additionalClassname: PropTypes.string,
  message: PropTypes.string,
};

export default AtomUrgencyMessage;
