import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { displayModal } from "actions/shared-actions";

export class AtomCustomerReviewsLink extends React.Component {
  static propTypes = {
    displayReviewPhotosModal: PropTypes.func,
    momentCount: PropTypes.number,
  };

  triggerReviewsModal = () => {
    this.props.displayReviewPhotosModal();
  };

  renderMomentCount = () => {
    if (this.props.momentCount && this.props.momentCount !== 0) {
      return <span> ({this.props.momentCount})</span>;
    }
  };

  render() {
    return (
      <div className="customer-reviews-link" onClick={this.triggerReviewsModal}>
        <div className="customer-reviews-link__text universal-small--semibold universal-underline">
          {this.renderMomentCount()}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    displayReviewPhotosModal: () => {
      dispatch(displayModal("REVIEW_PHOTOS"));
    },
  };
}

export default connect(null, mapDispatchToProps)(AtomCustomerReviewsLink);
