import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CheckboxPair from "components/source/new_taxonomy/checkbox-pair";
import { colorFilterMapping } from "rtr-constants";
import { connect } from "react-redux";

class FilterInputPairs extends React.Component {
  static propTypes = {
    disabledValues: PropTypes.array,
    maxCollapsedFilters: PropTypes.number,
    values: PropTypes.array,
    filterClass: PropTypes.string,
    filters: PropTypes.array,
    keyName: PropTypes.string,
    onChange: PropTypes.func,
    seoKey: PropTypes.string,
    seoPossibleFilters: PropTypes.array,
    style: PropTypes.string,
    informedFilters: PropTypes.array,
    showInformedFilters: PropTypes.bool,
  };

  static defaultProps = {
    disabledValues: [],
    values: [],
    showInformedFilters: true,
  };

  static displayName = "FilterInputPairs";

  constructor(props) {
    super(props);

    this.state = {
      isViewingAll: false,
    };
  }

  toggleViewAll = () => {
    this.setState({
      isViewingAll: !this.state.isViewingAll,
    });
  };

  renderViewAllToggle() {
    const { values, maxCollapsedFilters } = this.props;
    if (!maxCollapsedFilters || values.length <= maxCollapsedFilters) {
      return null;
    }

    const buttonClassName = classNames("filter-input-pairs__toggle-view-all", {
      collapse: this.state.isViewingAll,
    });
    const buttonCopy = this.state.isViewingAll ? "See Less" : "See More";

    return (
      <button className={buttonClassName} onClick={this.toggleViewAll}>
        {buttonCopy}
      </button>
    );
  }

  render() {
    const {
      disabledValues,
      filterClass,
      filters,
      keyName,
      onChange,
      seoKey,
      seoPossibleFilters,
      style,
      maxCollapsedFilters,
      values,
      informedFilters,
      showInformedFilters,
    } = this.props;

    const filterClassAppend = filterClass ? filterClass : "";
    const isColorFilter = keyName === "colors" || keyName === "primarycolor"; // Accomodating both regular gris and SF Next
    const isSizeFilter = ["canonicalSizes", "secondarySizes"].indexOf(keyName) >= 0;
    const filterInputClassName = classNames(`filter-input-pairs ${filterClassAppend}`, {
      "filter-input-pairs--colors": isColorFilter,
      "filter-input-pairs--sizes": isSizeFilter,
    });

    const visibleValues =
      maxCollapsedFilters && !this.state.isViewingAll ? values.slice(0, maxCollapsedFilters) : values;

    return (
      <div className={filterInputClassName} style={style} data-test-id="filter-input-pairs">
        {visibleValues?.map((filterValue, i) => {
          const selected = filters?.includes(filterValue.id);
          const isDisabled = disabledValues.some(val => filterValue.id === val.id);

          let optionalClassName, optionalTitle;

          if (isColorFilter) {
            const nameAppend = colorFilterMapping[filterValue.id] ? colorFilterMapping[filterValue.id] : filterValue.id;
            optionalClassName = "grid-" + nameAppend.toLowerCase(); // to acommodate disco search filters
            optionalTitle = filterValue.displayName;
          }

          const resultsCount = () => {
            if (Array.isArray(informedFilters) && showInformedFilters) {
              const informedFilterValues = informedFilters?.map(filter => filter.values).flat();
              const filterValueResultsCount = informedFilterValues.find(
                informedFilterValue => informedFilterValue?.id.toLowerCase() === filterValue.id
              );

              return filterValueResultsCount?.count ? filterValueResultsCount?.count : 0;
            }

            return;
          };

          return (
            <CheckboxPair
              key={i}
              optionalClassName={optionalClassName}
              filterGroupKey={keyName}
              id={filterValue.id}
              onChange={onChange}
              name={"filters[" + keyName + "][]"}
              checked={selected}
              label={filterValue.displayName}
              optionalTitle={optionalTitle}
              checkboxIconClass={filterValue.checkboxIconClass}
              seoKey={seoKey}
              seoPossibleFilters={seoPossibleFilters}
              disabled={isDisabled}
              resultsCount={resultsCount()}
            />
          );
        })}
        {this.renderViewAllToggle()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    informedFilters: state.informedFilters,
  };
};

export default connect(mapStateToProps)(FilterInputPairs);
