import React from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import HeartIcon from "../user_product_ratings/heart-icon";
import { isStorefrontNext } from "../../../helpers/environment-helpers";
import { onEnterPress } from "helpers/a11y-helper";

class CheckboxPair extends React.Component {
  static propTypes = {
    checkboxIconClass: PropTypes.string,
    resultsCount: PropTypes.number,
    filterGroupKey: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    additionalClassName: PropTypes.string,
    optionalClassName: PropTypes.string,
    seoKey: PropTypes.string,
    label: PropTypes.string,
    seoPossibleFilters: PropTypes.array,
    disabled: PropTypes.bool,
    optionalTitle: PropTypes.string,
    name: PropTypes.string,
  };

  static SeoFilterMappings = {
    floor_length: "long",
    mid_thigh_length: "mid-length",
  };

  getId = () => {
    return this.props.filterGroupKey + "-" + this.props.id;
  };

  fireChange = () => {
    this.props.onChange({
      filterGroupKey: this.props.filterGroupKey,
      name: this.props.id,
      selected: !this.props.checked,
    });
  };

  getClassNames = () => {
    var className = "input-wrapper";

    if (this.props.additionalClassName) {
      className += ` ${this.props.additionalClassName}`;
    }

    if (this.props.optionalClassName) {
      className += ` ${this.props.optionalClassName}`;
    }

    return className;
  };

  renderLabelWithOrWithoutSEO = () => {
    // If we have an SEO grid route for this filter, render a hidden
    // link that Google can crawl so that it can index our filtered SEO grids
    // i.e. if we're on a /c/ball-gowns grid, the red filter will have a hidden
    // link to /c/ball-gowns/red
    if (!this.props.id || !this.props.seoKey) {
      return this.props.label;
    }
    var mappedFilterName = this.constructor.SeoFilterMappings[this.props.id] || this.props.id.replace(/_/g, "-");
    if (_.contains(this.props.seoPossibleFilters, mappedFilterName)) {
      return (
        <a onClick={this.preventSEOClickThrough} href={`/c/${this.props.seoKey}/${mappedFilterName}`}>
          {this.props.label}
        </a>
      );
    } else {
      return this.props.label;
    }
  };

  preventSEOClickThrough = e => {
    this.fireChange();
    e.preventDefault();
    e.stopPropagation();
  };

  renderCheckboxPairImage = () => {
    const { checkboxIconClass } = this.props;
    if (!checkboxIconClass) {
      return;
    }

    // for hearts return the svg
    if (checkboxIconClass === "heart-icon") {
      return <HeartIcon />;
    }

    return <span key="checkbox-pair-image" className={"checkbox-pair-image " + checkboxIconClass} />;
  };

  renderLabelCount = () => {
    const { resultsCount, filterGroupKey } = this.props;

    // Add more filterGroupKeys here if needed
    const isSideBarFilter = filterGroupKey !== "canonicalSizes";

    if (resultsCount >= 0 && isStorefrontNext() && isSideBarFilter) {
      return (
        <span className="checkbox-pair-count" data-test-id="checkbox-pair-count">
          ({resultsCount})
        </span>
      );
    }

    return;
  };

  render() {
    var disabled = _.isUndefined(this.props.disabled) ? false : this.props.disabled;

    return (
      <div className={this.getClassNames()} title={this.props.optionalTitle}>
        <input
          type="checkbox"
          onChange={this.fireChange}
          name={this.props.name}
          defaultChecked={this.props.checked}
          checked={this.props.checked}
          disabled={disabled}
          value={this.props.id}
          id={this.getId()}
          data-test-id={this.getId()}
          onKeyDown={onEnterPress(this.fireChange)}
        />
        <label htmlFor={this.getId()}>
          {this.renderCheckboxPairImage()}
          {this.renderLabelWithOrWithoutSEO()}
          {this.renderLabelCount()}
        </label>
      </div>
    );
  }
}

export default CheckboxPair;
