// eslint-disable-next-line no-extra-semi

"use strict";

// New grids and the single products api return `exclusiveDesign: true/false` which
// can be used instead of consulting this file.

var privateLabelDesignersList = [
  {
    id: "31_phillip_lim_x_rtr",
    name: "3.1 Phillip Lim x RTR",
  },
  {
    id: "adam_lippes_x_rtr",
    name: "Adam Lippes x RTR",
  },
  {
    id: "ashley_park_x_rtr",
    name: "ASHLEY PARK x RTR",
  },
  {
    id: "atlein_x_rtr",
    name: "Atlein x RTR",
  },
  {
    id: "bibhu_mohapatra_x_rtr",
    name: "Bibhu Mohapatra x RTR",
  },
  {
    id: "busayo_x_rtr",
    name: "Busayo x RTR",
  },
  {
    id: "color_me_courtney",
    name: "Color Me Courtney",
  },
  {
    id: "derek_lam_10_crosby_x_rtr",
    name: "Derek Lam 10 Crosby x RTR",
  },
  {
    id: "esteban_cortazar_x_rtr",
    name: "Esteban Cortazar x RTR",
  },
  {
    id: "eudon_choi_x_rtr",
    name: "Eudon Choi x RTR",
  },
  {
    id: "great_jones",
    name: "Great Jones",
  },
  {
    id: "jason_wu_x_rtr",
    name: "Jason Wu x RTR",
  },
  {
    id: "kfkaufmanfranco_x_rtr",
    name: "KF/KaufmanFranco x RTR",
  },
  {
    id: "koche_x_rtr",
    name: "Koché x RTR",
  },
  {
    id: "louna",
    name: "Louna",
  },
  {
    id: "love_whit_by_whitney_port",
    name: "Love, Whit by Whitney Port",
  },
  {
    id: "marina_moscone_x_rtr",
    name: "Marina Moscone x RTR",
  },
  {
    id: "marissa_webb_x_rtr",
    name: "Marissa Webb x RTR",
  },
  {
    id: "obando_x_rtr",
    name: "Obando x RTR",
  },
  {
    id: "osman_yousefzada_x_rtr",
    name: "Osman Yousefzada x RTR",
  },
  {
    id: "pamela_love_x_rtr",
    name: "Pamela Love x RTR",
  },
  {
    id: "peter_som_x_rtr",
    name: "Peter Som x RTR",
  },
  {
    id: "plan_c_x_rtr",
    name: "PLAN C x RTR",
  },
  {
    id: "prabal_gurung_x_rtr",
    name: "Prabal Gurung x RTR",
  },
  {
    id: "rachel_roy_collection",
    name: "RACHEL ROY COLLECTION",
  },
  {
    id: "roksanda_x_rtr",
    name: "Roksanda x RTR",
  },
  {
    id: "ronny_kobo_x_rtr",
    name: "Ronny Kobo x RTR",
  },
  {
    id: "rosetta_getty_x_rtr",
    name: "Rosetta Getty x RTR",
  },
  {
    id: "rtr_now",
    name: "RTR NOW",
  },
  {
    id: "saunders_x_rtr",
    name: "Saunders x RTR",
  },
  {
    id: "simonmiller_x_rtr",
    name: "SIMONMILLER X RTR",
  },
  {
    id: "slate_willow",
    name: "Slate & Willow",
  },
  {
    id: "slate_willow_accessories",
    name: "Slate & Willow Accessories",
  },
  {
    id: "sweet_baby_jamie",
    name: "Sweet Baby Jamie",
  },
  {
    id: "thakoon_x_rtr",
    name: "Thakoon x RTR",
  },
  {
    id: "toccin_x_rtr",
    name: "TOCCIN X RTR",
  },
  {
    id: "tome_x_rtr",
    name: "TOME x RTR",
  },
  {
    id: "victor_alfaro_x_rtr",
    name: "Victor Alfaro x RTR",
  },
];

export default privateLabelDesignersList;
