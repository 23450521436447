import { getProductImageUrlsByOrientation, ProductImageOrientation, includesBumpImages } from "./product-image-helpers";
import { AccessoryCategoryIds, AccessoryRootCategoryIdDisco } from "rtr-constants";
import { isStorefrontNext } from "helpers/environment-helpers";
// Currently we return all the images from Dorothy to our Ruby layer, but we do not display them all.
// This function determines the product images we want to display and the order of those images.
// Most of these image tags are self-explanatory, editorial is an outfits shot. Going forward we will have an outfit tag.

// Maternity Rotation is used for users who have told us that they are expecting in the future and we have a bumpFriendly image and user has not selected plus sizes

// [09/2024] Disco seems to be following different path structure I suppose for bump and plus images
// hence have changed some of the rotations to check for both path (follows ruby nomenclature) and legacy path
// (follows disco nomenclature)
function productImageRotation(sizes, product, showMaternityBumpImage, noModelImageFirst = false) {
  const sizesAsInts = sizes.map(s => parseInt(s, 10));
  const missyOnlyRotation = [
    ProductImageOrientation.FRONT.path,
    ProductImageOrientation.BACK.path,
    ProductImageOrientation.SIDE.path,
    ProductImageOrientation.TOP.path,
    ProductImageOrientation.WITH_MODEL.path,
    ProductImageOrientation.EDITORIAL.path,
    ProductImageOrientation.NO_MODEL.path,
    ProductImageOrientation.NO_MODEL.legacyPath,
    ProductImageOrientation.FRONT_PLUS.path,
    ProductImageOrientation.FRONT_PLUS.legacyPath,
    ProductImageOrientation.BACK_PLUS.path,
    ProductImageOrientation.BACK_PLUS.legacyPath,
    ProductImageOrientation.SIDE_PLUS.path,
    ProductImageOrientation.SIDE_PLUS.legacyPath,
  ];
  const plusOnlyRotation = [
    ProductImageOrientation.FRONT_PLUS.path,
    ProductImageOrientation.FRONT_PLUS.legacyPath,
    ProductImageOrientation.BACK_PLUS.path,
    ProductImageOrientation.BACK_PLUS.legacyPath,
    ProductImageOrientation.SIDE_PLUS.path,
    ProductImageOrientation.SIDE_PLUS.legacyPath,
    ProductImageOrientation.TOP.path,
    ProductImageOrientation.WITH_MODEL.path,
    ProductImageOrientation.EDITORIAL.path,
    ProductImageOrientation.NO_MODEL.path,
    ProductImageOrientation.FRONT.path,
    ProductImageOrientation.BACK.path,
    ProductImageOrientation.SIDE.path,
  ];
  const mixedRotation = [
    ProductImageOrientation.FRONT.path,
    ProductImageOrientation.BACK.path,
    ProductImageOrientation.FRONT_PLUS.path,
    ProductImageOrientation.FRONT_PLUS.legacyPath,
    ProductImageOrientation.BACK_PLUS.path,
    ProductImageOrientation.BACK_PLUS.legacyPath,
    ProductImageOrientation.TOP.path,
    ProductImageOrientation.WITH_MODEL.path,
    ProductImageOrientation.EDITORIAL.path,
    ProductImageOrientation.NO_MODEL.path,
  ];
  const maternityRotation = [
    ProductImageOrientation.BUMP_FRIENDLY.path,
    // adding legacy path as Disco is sending as "bump_friendly" format while ruby uses "bumpFriendly"
    ProductImageOrientation.BUMP_FRIENDLY.legacyPath,
    ProductImageOrientation.BUMP_FRONT.path,
    ProductImageOrientation.BUMP_FRONT.legacyPath,
    ProductImageOrientation.BUMP_SIDE.path,
    ProductImageOrientation.BUMP_SIDE.legacyPath,
    ProductImageOrientation.BACK.path,
    ProductImageOrientation.SIDE.path,
    ProductImageOrientation.NO_MODEL.path,
  ];
  let imageOrder;

  const plusSizeCutOff = 12;
  const inPlusExperience = sizesAsInts.length && Math.min(...sizesAsInts) > plusSizeCutOff;
  let inMissyExperience = sizesAsInts.length && Math.max(...sizesAsInts) <= plusSizeCutOff;
  const requiredBumpFriendlyImages = getProductImageUrlsByOrientation(
    product?.images,
    [
      ProductImageOrientation.BUMP_FRIENDLY.path,
      ProductImageOrientation.BUMP_FRIENDLY.legacyPath,
      ProductImageOrientation.BUMP_FRONT.path,
      ProductImageOrientation.BUMP_SIDE.path,
    ],
    false
  );

  // to support both ruby and next
  const isAccessory = isStorefrontNext()
    ? product.categoryRoot === AccessoryRootCategoryIdDisco
    : AccessoryCategoryIds.includes(product.category?.id) || false;
  const hasBumpImages = product?.images ? includesBumpImages(product.images) : false;

  if (isStorefrontNext()) {
    // the requirements are slightly different now compared to the old code, hence re-evaluating in case of Next
    inMissyExperience = sizesAsInts.length && Math.min(...sizesAsInts) <= plusSizeCutOff;

    const hasPlusExperienceImages = getProductImageUrlsByOrientation(
      product?.images,
      [ProductImageOrientation.FRONT_PLUS.path, ProductImageOrientation.FRONT_PLUS.legacyPath],
      false
    );

    // updating storefrontNext
    if (isAccessory) {
      imageOrder = mixedRotation;
    } else if (showMaternityBumpImage && requiredBumpFriendlyImages && hasBumpImages) {
      // if told maternity and has maternity images then show maternity images
      imageOrder = maternityRotation;
    } else if (inPlusExperience && hasPlusExperienceImages) {
      imageOrder = plusOnlyRotation;
    } else {
      imageOrder = missyOnlyRotation;
    }
  } else {
    // keeping the legacy as is
    if (
      showMaternityBumpImage &&
      requiredBumpFriendlyImages &&
      inPlusExperience &&
      getProductImageUrlsByOrientation(product?.images, [ProductImageOrientation.FRONT_PLUS.path], false)
    ) {
      imageOrder = plusOnlyRotation;
    } else if (showMaternityBumpImage && requiredBumpFriendlyImages && !isAccessory && hasBumpImages) {
      // if there are no maternity images we return missyOnlyRotation
      imageOrder = maternityRotation;
    } else if (!getProductImageUrlsByOrientation(product?.images, [ProductImageOrientation.FRONT_PLUS.path], false)) {
      imageOrder = missyOnlyRotation;
    } else if (!sizesAsInts.length) {
      imageOrder = mixedRotation;
    } else if (inPlusExperience) {
      imageOrder = plusOnlyRotation;
    } else if (inMissyExperience) {
      imageOrder = missyOnlyRotation;
    } else {
      imageOrder = mixedRotation;
    }
  }

  if (noModelImageFirst) {
    //if true move "noModel" image order to front of the image rotation
    imageOrder.unshift(imageOrder.pop());
  }

  return imageOrder;
}

export default productImageRotation;
